import { ErrorHandler, inject, Injectable } from '@angular/core';
import { ErrorStore } from '../error.store';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  private errorStore = inject(ErrorStore);

  handleError(error: Error) {
    console.error(error);
    this.errorStore.setJavascriptError(error);
  }
}
