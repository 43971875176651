import { RoutesCommon as COMMON } from './routes-common.model';
import { RoutesSettings as SETTINGS } from './routes-settings.model';
import { RoutesDashboard as DASHBOARD } from './routes-dashboard.model';
import { RoutesNotifications as NOTIFICATIONS } from './routes-notifications.model';
import { RoutesHome as HOME } from './routes-home.model';

const routes = {
  COMMON,
  SETTINGS,
  DASHBOARD,
  NOTIFICATIONS,
  HOME,
};

export function RoutePaths() {
  return routes;
}
