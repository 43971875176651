
import { DateTime } from 'luxon';

export const getDateFormat = (language: string): string => {
    const date = DateTime.now();
    const formattedDate = date.setLocale(language).toLocaleString();

    switch (Number(formattedDate.split('/')[0])) {
        case date.day: return 'dd/MM/yyyy';
        case date.month: return 'MM/dd/yyyy';
        default: return 'dd/MM/yyyy';
    }
}