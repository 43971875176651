export enum RoutesSettings {
  SETTINGS = 'settings',
  PROFILE = 'profile',
  INTEGRATIONS = 'integrations',
  ACCOUNT = 'account',
  CONTRACTS = 'contracts',
  ALL_CONTRACTS = 'all',
  USERS = 'users',
  USER_ROLES_NEW = 'users/role/new',
  USER_ROLE_EDIT = 'users/role/:roleId',
  CONTRACT = 'contract',
  NOTIFICATIONS = 'notifications',
  BILLING = 'billing',
  LOGOS = 'logos',
  NAVIGATE_PROFILE = '/settings/profile',
  NAVIGATE_ACCOUNT = '/settings/account',
  NAVIGATE_CONTRACTS = '/settings/contracts/model/all',
  NAVIGATE_NOTIFICATIONS = '/settings/notifications',
  NAVIGATE_BILLING = '/settings/billing',
  NAVIGATE_LOGOS = '/settings/logos',
  NAVIGATE_INTEGRATIONS = '/settings/integrations',
  NAVIGATE_USERS = '/settings/users',
}
