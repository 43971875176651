export const enum Protocol {
  http = 'http://',
  https = 'https://',
}

export const prefix = Protocol.https

export const APP_URL_STAGE = {
  accounts: prefix + 'accounts.stage.actuar.com',
  actuar_app: prefix + 'app.stage.actuar.com',
  treino_app: prefix + 'treino.stage.actuar.com',
  actuar_sign: prefix + 'sign.stage.actuar.com',
  afig_app: prefix + 'app.afig.stage.actuar.com',
  actuar_acesso: prefix + 'acesso.stage.actuar.com',
}

export const API_STAGE_ACTUAR = {
  odata: prefix +'odata.actuar.cloud',
  odata_goods_service: prefix +'goodservice-api.stage.actuar.cloud/Odata',
  odata_person_service: prefix +'personservice-api.stage.actuar.cloud/Odata',
  odata_payable_service: prefix +'payableservice-api.stage.actuar.cloud/Odata',
  odata_work_service: prefix +'workservice-api.stage.actuar.cloud/Odata',
  odata_crm_service: prefix +'crmservice-api.stage.actuar.cloud/Odata',
  odata_generalfinance_service: prefix + 'generalfinanceservice-api.stage.actuar.cloud/Odata',
  odata_account_service: prefix + 'accountservice-api.stage.actuar.cloud/Odata',
  odata_receivable_service: prefix +'receivableservice-api.stage.actuar.cloud/Odata',
  odata_email_service: prefix +'emailservice-api.stage.actuar.cloud/Odata',
  odata_contract: prefix +'contractservice-api.stage.actuar.cloud/Odata',
  odata_user_service: prefix +'userservice-api.stage.actuar.cloud/Odata',
  odata_workout_service: prefix + 'workoutservice-api.stage.actuar.cloud/Odata',
  odata_post_service: prefix + 'postservice-api.stage.actuar.cloud/Odata',
  odata_activity_service: prefix + 'activityservice-api.stage.actuar.cloud/Odata',
  base: prefix +'actuar-api.actuar.cloud',
  afig: prefix +'afig-api.stage.actuar.cloud',
  paguela: prefix +'paguelacore-api.stage.actuar.cloud',
  api_cep: prefix +'cep-api.actuar.cloud',
  auth: prefix +'authentication-api.dev.actuar.biz',
  chat: prefix +'tst.actuar.net/chat',
  file_manager: prefix +'filegroup-api.actuar.cloud',
  treino: prefix +'treino-api.actuar.cloud',
  facial: prefix +'facial-api.actuar.cloud',
  acesso: prefix +'acesso-api.actuar.cloud',
  caixa: prefix +'caixa-api.actuar.cloud',
  helpdesk: prefix +'helpdesk-api.actuar.cloud',
  ajustes: prefix +'tst.actuar.net/actuar',
  files_api: prefix +'files-api.actuar.cloud',
  contract: prefix +'contract-api.actuar.cloud',
  billing: prefix +'billing-api.actuar.cloud',
  gympass: prefix +'gympass-api.actuar.cloud',
  client_notification: prefix +'clientnotification-api.actuar.cloud',
  invoicer: prefix +'invoicer-api.actuar.cloud',
  acessoLocalhost: 'localhost:63312',
  userService: prefix +'userservice-api.actuar.cloud',
  personService: prefix +'personservice-api.actuar.cloud',
  file_group: prefix +'filegroup-api.actuar.cloud',
  account_service: prefix + 'accountservice-api.actuar.cloud',
  goods: prefix + 'goodservice-api.stage.actuar.cloud',
  payable_service: prefix + 'payableservice-api.stage.actuar.cloud',
  workService: prefix + 'workservice-api.stage.actuar.cloud',
  crm_service: prefix + 'crmservice-api.stage.actuar.cloud',
  generalfinance_service: prefix + 'generalfinanceservice-api.stage.actuar.cloud',
  receivable_service: prefix + 'receivableservice-api.stage.actuar.cloud',
  subscription_service: prefix + 'subscriptionservice-api.stage.actuar.cloud',
  email_service: prefix + 'emailservice-api.stage.actuar.cloud',
  consummation: prefix + 'consummation-api.stage.actuar.cloud',
  workout_service: prefix + 'workoutservice-api.stage.actuar.cloud',
  workout_view: prefix + 'workoutview-api.stage.actuar.cloud',
  post_service: prefix + 'postservice-api.stage.actuar.cloud',
  activity_service: prefix + 'activityservice-api.stage.actuar.cloud',
};
