export const enum Protocol {
  http = 'http://',
  https = 'https://',
}

export const prefix = Protocol.https

export const APP_URL_LOCAL = {
  accounts: 'http://localhost:4300',
  actuar_app: 'http://localhost:4200',
  treino_app: 'http://localhost:4400',
  actuar_sign: 'http://localhost:4500',
  afig_app: 'http://localhost:4600',
  actuar_acesso: 'http://localhost:4700',
  actuar_mobile: 'http://localhost:4800',
}

export const API_LOCAL_ACTUAR = {
  odata: prefix +'odata.actuar.cloud',
  odata_goods_service: prefix +'goodservice-api.actuar.cloud/Odata',
  odata_person_service: prefix +'personservice-api.actuar.cloud/Odata',
  odata_payable_service: prefix +'payableservice-api.actuar.cloud/Odata',
  odata_work_service: prefix +'workservice-api.actuar.cloud/Odata',
  odata_crm_service: prefix +'crmservice-api.actuar.cloud/Odata',
  odata_generalfinance_service: prefix + 'generalfinanceservice-api.actuar.cloud/Odata',
  odata_account_service: prefix + 'accountservice-api.actuar.cloud/Odata',
  odata_receivable_service: prefix +'receivableservice-api.actuar.cloud/Odata',
  odata_email_service: prefix +'emailservice-api.actuar.cloud/Odata',
  odata_contract: prefix +'contractservice-api.actuar.cloud/Odata',
  odata_user_service: prefix +'userservice-api.actuar.cloud/Odata',
  odata_workout_service: prefix + 'workoutservice-api.dev.actuar.biz/Odata',
  odata_post_service: prefix + 'postservice-api.dev.actuar.biz/Odata',
  odata_activity_service: prefix + 'activityservice-api.dev.actuar.biz/Odata',
  base: prefix +'actuar-api.actuar.cloud',
  afig: prefix +'afig-api.actuar.cloud',
  paguela: prefix +'paguelacore-api.dev.cloud',
  api_cep: prefix +'cep-api.actuar.cloud',
  auth: prefix +'authentication-api.dev.actuar.biz',
  chat: prefix +'tst.actuar.net/chat',
  file_manager: prefix +'filegroup-api.actuar.cloud',
  treino: prefix +'treino-api.actuar.cloud',
  facial: prefix +'facial-api.actuar.cloud',
  acesso: prefix +'acesso-api.actuar.cloud',
  caixa: prefix +'caixa-api.actuar.cloud',
  helpdesk: prefix +'helpdesk-api.actuar.cloud',
  ajustes: prefix +'tst.actuar.net/actuar',
  files_api: prefix +'files-api.actuar.cloud',
  contract: prefix +'contractservice-api.actuar.cloud',
  billing: prefix +'billing-api.actuar.cloud',
  gympass: prefix +'gympass-api.actuar.cloud',
  client_notification: prefix +'clientnotification-api.actuar.cloud',
  invoicer: prefix +'invoicer-api.actuar.cloud',
  acessoLocalhost: 'localhost:63312',
  userService: prefix +'userservice-api.actuar.cloud',
  personService: prefix +'personservice-api.actuar.cloud',
  file_group: prefix +'filegroup-api.actuar.cloud',
  account_service: prefix + 'accountservice-api.actuar.cloud',
  goods: prefix + 'goodservice-api.actuar.cloud',
  payable_service: prefix + 'payableservice-api.actuar.cloud',
  workService: prefix + 'workservice-api.actuar.cloud',
  crm_service: prefix + 'crmservice-api.actuar.cloud',
  generalfinance_service: prefix + 'generalfinanceservice-api.actuar.cloud',
  receivable_service: prefix + 'receivableservice-api.actuar.cloud',
  subscription_service: prefix + 'subscriptionservice-api.actuar.cloud',
  email_service: prefix + 'emailservice-api.actuar.cloud',
  consummation: prefix + 'consummation-api.actuar.cloud',
  workout_service: prefix + 'workoutservice-api.dev.actuar.biz',
  workout_view: prefix + 'workoutview-api.dev.actuar.biz',
  post_service: prefix + 'postservice-api.dev.actuar.biz',
  activity_service: prefix + 'activityservice-api.dev.actuar.biz',
}
