import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { computed, DestroyRef, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';

export interface IRouterState {
  route: {
    current: string;
    last: string | null;
    navigationHistory: string[];
    hasPreviousRoutes: boolean;
  };
  backNavigationPath: string | null;
  currentRouteId: string;
  url: string;
}

export const initialRouterStore: IRouterState = {
  route: {
    current: '',
    last: null,
    navigationHistory: [],
    hasPreviousRoutes: false,
  },
  backNavigationPath: null,
  currentRouteId: '',
  url: null,
};

export const RouterStore = signalStore(
  { providedIn: 'root' },
  withState(initialRouterStore),
  withComputed((
    state,
    activatedRoute = inject(ActivatedRoute),
    currentRoute = toSignal(activatedRoute.params),

    ) => {
    return {
      getCurrentRoute: computed(() => state.url()),
      getCurrentRouteParams: computed(() => currentRoute()),
      getCurrentRouteQueryParams: computed(() => toSignal(activatedRoute.queryParams)),
    }
  }),
  withMethods((
    state,
    router = inject(Router)
  ) => {
    return {
      setBackNavigationPath: (path: string) => {
        patchState(state, {backNavigationPath: path});
      },
      navigate: (path: [string]) => {
        router.navigate(path).then();
      },
      goBack: () => {
        router.navigate([state.backNavigationPath()]).then();
      },
      clearBackNavigation: () => {
        patchState(state, {backNavigationPath: null});
      },
      setRouteId: (currentRouteId: string) => {
        patchState(state, { currentRouteId });
      },
      clearRouteId: () => {
        patchState(state, { currentRouteId: '' });
      }
    }
  }),
  withHooks((
    state,
    router = inject(Router),
    destroyRef = inject(DestroyRef)
  ) => ({
    onInit: () => {
      const updateUrl = () => {
        patchState(state, { url: router.routerState.snapshot.url });
      }
      updateUrl();
      router.events
        .pipe(
          takeUntilDestroyed(destroyRef),
          filter((event) => event instanceof NavigationEnd))
        .subscribe((
          event
        ) => {
          updateUrl();
        });
      }
    })
  )
);
