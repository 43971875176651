import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { ELocalStorageKeys, IAuthAccessToken, IAuthLoginPayload } from './entities/authentication.interface';
import { computed, inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, retry, switchMap, take, tap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { setPropError, setPropLoaded, setPropLoading, withReqState } from '@application/data-access';
import { Encrypt } from '@shared/utils';
import { LocalStorageFacade } from '@application/localstorage';
import { AuthService } from './infra/auth.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { APP_CONFIG, App_url_type_by_AppSource_enum, AppSource, AppUrlOptions } from '@application/config';
import { AlertStore } from '@alert/data-access';
import { WebAuthnService } from './infra/web-authn.service';
import { User } from './entities/user';
import { ServerMockService } from './infra/server-mock.service';
import { RouterStore } from '@route/data-access';
import { Capacitor } from '@capacitor/core';

export interface IAuthStore {
  authData: IAuthAccessToken | null;
  webauthn: any;
  tempLoginPayload: IAuthLoginPayload;
  emailToLogin: string;
  rememberNextLoginEmail: boolean;
  redirectToApp: AppUrlOptions;
}

export const initialAuthStore: IAuthStore = {
  authData: null,
  webauthn: null,
  tempLoginPayload: null,
  emailToLogin: null,
  rememberNextLoginEmail: false,
  redirectToApp: null
};

export const AuthStore = signalStore(
  {
    providedIn: 'root'
  },
  withState(initialAuthStore),
  withComputed((state, observe = toObservable(state.authData)) => ({
    getAuthData: computed( () => state.authData()),
    isLoggedIn: computed(() => !!state.authData()),
    getTempLoginPayload: computed(() => state.tempLoginPayload()),
    getEmailToLogin: computed(() => state.emailToLogin()),
    observableAuthData: computed(() => observe),
  })),
  withMethods((
    state,
    authService = inject(AuthService),
    crypt = inject(Encrypt),
    localStorage = inject(LocalStorageFacade),
    alertStore = inject(AlertStore),
    webAuthnService = inject(WebAuthnService),
    serverMockService = inject(ServerMockService),
    appConfig = inject(APP_CONFIG),
    routerStore = inject(RouterStore),
    ) => {

    const getAppSourceEnvironment = (appSource: AppSource): AppUrlOptions => {
      return App_url_type_by_AppSource_enum[
        AppSource[appSource] as keyof typeof App_url_type_by_AppSource_enum] as AppUrlOptions
    }

    const logout = () => {
      localStorage.removeItem(ELocalStorageKeys.auth);
      patchState(state, { authData: null });
      localStorage.removeItem(ELocalStorageKeys.webauthn);
      if (Capacitor.isNativePlatform()) {
        routerStore.navigate(['/onboarding']);
        return;
      }
      location.href = appConfig.appUrl.accounts + `?next=${getAppSourceEnvironment(appConfig.appSource)}`;
    }

    const getAllPersonsByUser = () => {
      return authService.getAllPersonsByUser();
    }

    const registeredCredential = localStorage.getItem('credentials');
    const preferPassword = localStorage.getItem('password');

    const transportBearerTo = (app: AppUrlOptions, data: any) => {
      if (!data) {
        return;
      }

      const goRedirect = () => {
        const appUrl = appConfig.appUrl[app];
        const base64 = window.btoa(JSON.stringify(data));

        window.location.href = `${appUrl}/transmission?token=${base64}`
      }

      if (!app) {
        getAllPersonsByUser()
          .pipe(take(1))
          .subscribe((result) => {
            app = getAppSourceEnvironment(result[0].AppSource);
          goRedirect();
          goRedirect();
        });
       return;
      }

      goRedirect();
    }

    const login = rxMethod<IAuthLoginPayload>(pipe(
            tap(() => {
              patchState(state, { ...setPropLoading('doLogin') });
            }),
            switchMap
            ((loginForm) => authService.login(loginForm).pipe(
              tapResponse({
                next: (authData) => {
            patchState(state, { authData, tempLoginPayload: loginForm, ...setPropLoaded('doLogin') });

            const defineRouteWay = () => {
              transportBearerTo(state.redirectToApp(), state.authData());
            }

            if (preferPassword) {
              defineRouteWay();
              return;
            }

           if (!!navigator.credentials && !!navigator.credentials.create) {

             if (!registeredCredential && !preferPassword) {
               routerStore.navigate(['/login/biometrics/create']);
               return;
             }

             defineRouteWay();
           }
          },
          error: (e) => {
            patchState(state, { authData: null, ...setPropError('doLogin', e)});
          },
        })
      ))
    ));
    const loginWebAuthn = (user: User[]) => {
      webAuthnService.webAuthnSignin(user)
        .then(() => {
          console.log('login successfully')
          const loginForm: IAuthLoginPayload  = {
            Email: user[0].email,
            Password: user[0].password,
            Origin: user[0].origin || 'actuar'
          }
          return login(loginForm)
        }).catch((error) => {

        console.log('FAIL', error);
      });
    }
    return {
      doLogin: (loginForm: IAuthLoginPayload) => {
        loginForm.Password = crypt.encryptMd5(
          loginForm.Email.toLowerCase() + loginForm.Password
        );
        return login(loginForm)
      },
      redirectToApp: () => {
        transportBearerTo('actuar_app', state.authData());
      },
      userHasWebAuthnCredentials: () => {
        return !!localStorage.getItem('credentials');
      },
      eraseWebauthnCredentials: () => {
        localStorage.removeItem('credentials');
      },
      setAuthData: (data: IAuthAccessToken) => {
        patchState(state, { authData: data });
      },
      registerWebAuthn: () => {
      const loginForm = state.tempLoginPayload();
      const authData = state.authData();
      if (!loginForm || !authData) {
        return;
      }
      const user: User = {
        id: loginForm.Email,
        email: loginForm.Email,
        credentials: [],
        password: loginForm.Password
      }
      webAuthnService.webAuthnSignup(user)
        .then((credential: PublicKeyCredential) => {
          const valid = serverMockService.registerCredential(user, credential);
          if (valid) {
            transportBearerTo('actuar_app', state.authData());
          }
        }).catch((error) => {
        alertStore.create({ message: 'LOGIN.BIOMETRICS.FAILED_TO_CREATE_BIOMETRY', mode: 'alert', title: 'LOGIN.BIOMETRICS.ALERT_TITLE_CREATE_BIOMETRY', type: 'danger' });
      });
    },
    loginWebAuthn: () => {
      const user = JSON.parse(localStorage.getItem('credentials'));
      loginWebAuthn(user);
    },
    setEmailLogin(emailToLogin: string) {
      patchState(state, { emailToLogin });
    },
    setTempLoginPayload(tempLoginPayload: IAuthLoginPayload) {
      patchState(state, { tempLoginPayload });
    },
    preferPassword: (password: boolean) => {
      localStorage.setItem('password', password);
    },
    setRedirectToApp(redirectToApp: AppUrlOptions) {
      patchState(state, { redirectToApp: redirectToApp });
    },
    rememberLoginEmail( rememberNextLoginEmail: boolean) {
      patchState(state, { rememberNextLoginEmail });
    },
    clearRememberEmail() {
      localStorage.removeItem(ELocalStorageKeys.rememberEmail);
    },
    loginWithTempData: () => login(state.tempLoginPayload()),
    refreshToken: rxMethod<void>(pipe(
     switchMap(
       () => authService.refreshToken(state.authData().TokenId).pipe(
        retry(2),
        tapResponse({
          next: (authData) => {
            patchState(state, { authData , ...setPropLoaded('refreshToken')});
            localStorage.setItem(
              ELocalStorageKeys.auth,
              JSON.stringify(authData)
            );
            if (authData.TokenId.includes('0000')) {
              logout();
            }
          },
          error: () => logout(),
        })
      ))
    )),
    changePersonAccount: rxMethod<string>(pipe(
      tap(() => {
        patchState(state, { ...setPropLoading('changePersonAccount') })}),
        switchMap((id) => authService.changePersonAccount(id).pipe(
          tapResponse({
            next: (authData) => {
              localStorage.setItem(ELocalStorageKeys.auth, JSON.stringify(authData));
              patchState(state, { authData, ...setPropLoaded('changePersonAccount')});
            },
            error: (e) => {
              patchState(state, { authData: null, ...setPropError('doLogin', e)});
            },
          })
        ))
      )
    ),
    logout() {
      logout();
    }
  }}),
  withHooks({
    onInit: (state) => {
      const localStorage = inject(LocalStorageFacade);
      const authData = JSON.parse(localStorage.getItem(ELocalStorageKeys.auth));
      const webauthn = JSON.parse(localStorage.getItem(ELocalStorageKeys.webauthn));
      if (authData) {
        patchState(state, { authData });
      }
      if (webauthn) {
        patchState(state, { webauthn, emailToLogin: webauthn[0].email});
      }
    }
  }),
  withReqState('doLogin', 'refreshToken', 'loginWithTempData', 'changePersonAccount'),
);
