interface IObject {
  [key: string]: any;
}
export const getChangedAttributes = (value: IObject, newValue: IObject): IObject | null => {
  const changedAttributes: IObject = {};
  for (const key in value) {
    if (newValue.hasOwnProperty(key) && !Object.is(value[key], newValue[key])) changedAttributes[key] = newValue[key];
  }
  return Object.keys(changedAttributes).length ? changedAttributes : null;
}
