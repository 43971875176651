import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { AlertStore } from '@alert/data-access';
import { HttpErrorResponse } from "@angular/common/http";

export interface IErrorStore {
  error: any;
}

export const initialErrorStore: IErrorStore = {
  error: null,
};

export const ErrorStore = signalStore(
  {providedIn: 'root'},
  withState(initialErrorStore),
  withMethods((state, alertStore = inject(AlertStore)) => {

    const getHttpErrorMessage = (error: HttpErrorResponse): string | null => {

      if (error?.error?.errors) {
        const errorKeys = Object.keys(error.error.errors);
        return error.error.errors[errorKeys[0]][0];
      }

      if (error?.error?.Validacao?.Erros) return error.error.Validacao.Erros;
      if (error?.error?.ValidationResult?.Errors) return error.error.ValidationResult.Errors.map((element: any) => element.ErrorMessage)[0];
      if (error?.error?.ModelState?.Erros) return error.error.ModelState.Erros;
      if (error?.error?.errors?.DomainValidations) return error.error.errors.DomainValidations;
      if (error?.error?.Erros) return error.error.Erros;
      return null;
    }

    const handleHttpError = (error: HttpErrorResponse): void => {
      const errorMessage: string | null = getHttpErrorMessage(error);

      if (errorMessage) openAlert(errorMessage);
      else {
        if (error.status === 400) openAlert('ALERT.ERROR_400');
        if (error.status === 404) openAlert('ALERT.ERROR_404');
        if (error.status === 401 || error.status === 403)  openAlert('ALERT.ERROR_401');
        if (error.status === 500) openAlert('ALERT.ERROR_500');
      }
    }

    const openAlert = (message: string) => alertStore.create({ message, mode: 'alert', title: 'ALERT.GENERIC_ERROR', type: 'danger' });

    return {
      setHttpError: (payload: HttpErrorResponse) => {
        patchState(state, { error: payload })
        handleHttpError(payload);
      },
      setJavascriptError: (payload: any) => {
        patchState(state, { error: payload })
      },
    }
  })

);
