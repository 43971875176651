import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  GetAllPersonsByUserResponse,
  IAuthAccessToken,
  IAuthLoginPayload
} from '../entities/authentication.interface';
import { APP_CONFIG, AppSource } from '@application/config';

@Injectable({providedIn: 'root'})
export class AuthService {
  private http = inject(HttpClient);
  private readonly appConfig = inject(APP_CONFIG);
  private readonly appSource = this.appConfig.appSource;
  private readonly apiUrl = this.appConfig.apiUrl;

  public login(payload: IAuthLoginPayload): Observable<IAuthAccessToken> {
    return this.http.post<IAuthAccessToken>(
        this.apiUrl.userService +
       '/Auth/Login',
      payload
    );
  }

  public refreshToken(payload: string): Observable<IAuthAccessToken> {
    return this.http.post<IAuthAccessToken>(
        this.apiUrl.userService +
       '/Auth/Refresh',
      {
        TokenId: payload,
        Origin: AppSource[this.appSource],
      },
      {
        withCredentials: true,
      }
    );
  }

  public changePersonAccount(personId: string): Observable<IAuthAccessToken> {
    return this.http.post<IAuthAccessToken>(`${this.apiUrl.userService}/Auth/ChangePerson?personId=${personId}`, {},{
      withCredentials: true
    });
  }

  public getAllPersonsByUser(origins?: string): Observable<GetAllPersonsByUserResponse[]> {
    return this.http.get<GetAllPersonsByUserResponse[]>(`${this.apiUrl.userService}/UserProfile/GetAllPersonsByUser${origins ? `?origins=${origins}` : ''}`,{
      withCredentials: true
    });
  }
}
