export const enum Protocol {
  http = 'http://',
  https = 'https://',
}

export const prefix = Protocol.https

export const APP_URL_DEVELOPMENT = {
  accounts: prefix + 'accounts.dev.actuar.com',
  actuar_app: prefix + 'app.dev.actuar.com',
  treino_app: prefix + 'treino.dev.actuar.com',
  actuar_sign: prefix + 'sign.dev.actuar.com',
  afig_app: prefix + 'app.afig.dev.actuar.com',
  actuar_acesso: prefix + 'acesso.dev.actuar.com',
}

export const API_DEVELOPMENT_ACTUAR = {
  odata: prefix + 'odata.dev.actuar.biz',
  odata_goods_service: prefix +'goodservice-api.dev.actuar.biz/Odata',
  odata_person_service: prefix +'personservice-api.dev.actuar.biz/Odata',
  odata_payable_service: prefix +'payableservice-api.dev.actuar.biz/Odata',
  odata_work_service: prefix +'workservice-api.dev.actuar.biz/Odata',
  odata_crm_service: prefix +'crmservice-api.dev.actuar.biz/Odata',
  odata_generalfinance_service: prefix + 'generalfinanceservice-api.dev.actuar.biz/Odata',
  odata_account_service: prefix + 'accountservice-api.dev.actuar.biz/Odata',
  odata_receivable_service: prefix +'receivableservice-api.dev.actuar.biz/Odata',
  odata_email_service: prefix +'emailservice-api.actuar.cloud/Odata',
  odata_contract: prefix +'contractservice-api.dev.actuar.biz/Odata',
  odata_user_service: prefix +'userservice-api.dev.actuar.biz/Odata',
  odata_workout_service: prefix + 'workoutservice-api.dev.actuar.biz/Odata',
  odata_post_service: prefix + 'postservice-api.dev.actuar.biz/Odata',
  odata_activity_service: prefix + 'activityservice-api.dev.actuar.biz/Odata',
  base: prefix +'actuar-api.dev.actuar.biz',
  afig: prefix +'afig-api.dev.actuar.biz',
  paguela: prefix +'paguelacore-api.dev.actuar.biz',
  api_cep: prefix +'cep-api.dev.actuar.biz',
  auth: prefix +'authentication-api.dev.actuar.biz',
  chat: prefix +'tst.actuar.net/chat',
  file_manager: prefix +'filegroup-api.dev.actuar.biz',
  treino: prefix +'treino-api.dev.actuar.biz',
  facial: prefix +'facial-api.dev.actuar.biz',
  acesso: prefix +'acesso-api.dev.actuar.biz',
  caixa: prefix +'caixa-api.dev.actuar.biz',
  helpdesk: prefix +'helpdesk-api.dev.actuar.biz',
  ajustes: prefix +'tst.actuar.net/actuar',
  files_api: prefix +'files-api.dev.actuar.biz',
  contract: prefix +'contractservice-api.dev.actuar.biz',
  billing: prefix +'billing-api.dev.actuar.biz',
  gympass: prefix +'gympass-api.dev.actuar.biz',
  client_notification: prefix +'clientnotification-api.dev.actuar.biz',
  invoicer: prefix +'invoicer-api.dev.actuar.biz',
  acessoLocalhost: 'localhost:63312',
  userService: prefix +'userservice-api.dev.actuar.biz',
  personService: prefix +'personservice-api.dev.actuar.biz',
  file_group: prefix +'filegroup-api.dev.actuar.biz',
  account_service: prefix + 'accountservice-api.dev.actuar.biz',
  goods: prefix + 'goodservice-api.dev.actuar.biz',
  payable_service: prefix + 'payableservice-api.dev.actuar.biz',
  workService: prefix + 'workservice-api.dev.actuar.biz',
  crm_service: prefix + 'crmservice-api.dev.actuar.biz',
  generalfinance_service: prefix + 'generalfinanceservice-api.dev.actuar.biz',
  receivable_service: prefix + 'receivableservice-api.dev.actuar.biz',
  subscription_service: prefix + 'subscriptionservice-api.dev.actuar.biz',
  email_service: prefix + 'emailservice-api.actuar.cloud',
  consummation: prefix + 'consummation-api.dev.actuar.biz',
  workout_service: prefix + 'workoutservice-api.dev.actuar.biz',
  workout_view: prefix + 'workoutview-api.dev.actuar.biz',
  post_service: prefix + 'postservice-api.dev.actuar.biz',
  activity_service: prefix + 'activityservice-api.actuar.cloud',
}
