import { EntityState } from "@ngrx/entity";
import { patchState, signalStore, type, withMethods, withState } from "@ngrx/signals";
import { IAlertItem, TAnswerType } from "../entities/alerts.interface";
import { removeEntity, withEntities, updateEntity, addEntity, entityConfig } from "@ngrx/signals/entities";
import { EAlertModes } from '../entities/alerts.enum';
import { v4 as uuidv4 } from 'uuid';
import { Observable } from "rxjs";
import { Injector, effect, inject, untracked } from "@angular/core";

export const TIME_TO_CLOSE_ALERT = 3 * 1000;

export interface IAlertStore {
    alertList: EntityState<IAlertItem>;
}

export const initialAlertStore: IAlertStore = {
    alertList: null,
};

const alertItemConfig = entityConfig({
  entity: type<IAlertItem>(),
  collection: 'alertList',
  selectId: (alert: IAlertItem) => alert.id,
})
export const AlertStore = signalStore(
    { providedIn: 'root' },
    withEntities(alertItemConfig),
    withState<IAlertStore>(initialAlertStore),
    withMethods((store, injector = inject(Injector)) => {
        const remove = (id: string) => patchState(store, removeEntity(id, alertItemConfig));
        return {
            create(payload: IAlertItem): Observable<TAnswerType> {
                const alert: IAlertItem = { ...payload, id: uuidv4() };
                patchState(store, addEntity(alert, alertItemConfig));

                if (alert.mode === EAlertModes.ALERT) setTimeout(() => remove(alert.id), TIME_TO_CLOSE_ALERT);

                return new Observable(observer => {
                    effect(() => {
                        const currentAlert = store.alertListEntityMap()[alert.id];
                        untracked(() => {
                            if (currentAlert) {
                                if (currentAlert.answer !== undefined) {
                                    observer.next(currentAlert.answer);
                                    observer.complete();
                                    remove(alert.id);
                                }
                            } else observer.complete();
                        })
                    }, { injector });
                });
            },
            confirm(id: string): void {
                patchState(store, updateEntity({ id, changes: { answer: true } }, alertItemConfig));
            },
            cancel(id: string): void {
                patchState(store, updateEntity({ id, changes: { answer: false } }, alertItemConfig));
            },
            selectOption(id: string, answer: 'secondaryButton' | 'primaryButton'): void {
                patchState(store, updateEntity({ id, changes: { answer } }, alertItemConfig));
            },
            genericSuccess(): void {
                this.create({ type: 'success', title: 'ALERT.TITLE_SUCCESS', message: 'ALERT.SUCCESS', mode: EAlertModes.ALERT });
            },
            genericError(): void {
                this.create({ type: 'danger', title: 'ALERT.TITLE_ERROR', message: 'ALERT.GENERIC_ERROR', mode: EAlertModes.ALERT });
            },
        }
    }),
);
