import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LocalStorageFacade {
  private readonly actuarPrefixKey = 'actuar/';
  public setItem(key: string, value: any): void {
    const _key: string = this.actuarPrefixKey + key;
    return localStorage.setItem(_key, value);
  }

  public getItem(key: string): any {
    const _key: string = this.actuarPrefixKey + key;
    return localStorage.getItem(_key);
  }

  public removeItem(key: string): void {
    const _key: string = this.actuarPrefixKey + key;
    return localStorage.removeItem(_key);
  }

  public clearAllData(): void {
    return localStorage.clear();
  }
}
