import { InjectionToken, ValueProvider } from '@angular/core';
import { API_DEVELOPMENT_ACTUAR, APP_URL_DEVELOPMENT } from './env/environment.development';
import { API_PRODUCTION_ACTUAR, APP_URL_PRODUCTION } from './env/environment.production';
import { API_LOCAL_ACTUAR, APP_URL_LOCAL } from './env/environment';
import { API_STAGE_ACTUAR, APP_URL_STAGE } from './env/environment.stage';

export type Environment = 'local' | 'dev' | 'stage' | 'prod';

export type AppConfig = {
  version: string;
  apiUrl: ApiUrlsType;
  environment: Environment;
  appSource: AppSource;
  appUrl: APP_URLType;
};

export enum AppSource {
  None = 0,
  Actuar = 1,
  Treino = 2,
  Afig = 3,
  FacilFit = 4,
  PagueLa = 5,
  Accounts
}

export enum App_url_type_by_AppSource_enum {
  "Actuar" = "actuar_app",
  "Treino" = "treino_app",
  "Afig" = "afig_app",
  "FacilFit" = "facilfit_app",
  "PagueLa" = "pague_la_app",
  "Accounts" = "accounts"
}

export type APP_URLType = {
    accounts: string;
    actuar_app: string;
    treino_app: string;
    actuar_sign: string;
    afig_app: string;
    actuar_acesso: string;
}

export type AppUrlOptions =
  "accounts"|
  "actuar_app"|
  "treino_app"|
  "actuar_sign"|
  "afig_app"|
  "actuar_acesso"

export type ApiUrlsType = {
    odata: string;
    odata_goods_service: string;
    odata_person_service: string;
    odata_payable_service: string;
    odata_work_service: string;
    odata_crm_service: string;
    odata_generalfinance_service: string;
    odata_account_service: string;
    odata_receivable_service: string;
    odata_email_service: string;
    odata_contract: string;
    odata_user_service: string;
    odata_workout_service: string;
    odata_post_service: string;
    odata_activity_service: string;
    base: string;
    afig: string;
    paguela: string;
    api_cep: string;
    auth: string;
    chat: string;
    file_manager: string;
    treino: string;
    facial: string;
    acesso: string;
    caixa: string;
    helpdesk: string;
    ajustes: string;
    files_api: string;
    contract: string;
    billing: string;
    gympass: string;
    client_notification: string;
    invoicer: string;
    acessoLocalhost: string;
    account_service: string;
    userService: string;
    personService: string;
    file_group: string;
    goods: string;
    payable_service: string;
    workService: string;
    crm_service: string;
    generalfinance_service: string;
    receivable_service: string;
    subscription_service: string;
    email_service: string;
    consummation: string;
    workout_service: string;
    workout_view: string;
    post_service: string;
    activity_service: string;
};

export const ProductionEnv: ApiUrlsType  = API_PRODUCTION_ACTUAR;
export const DevelopmentEnv: ApiUrlsType  = API_DEVELOPMENT_ACTUAR;
export const LocalEnv: ApiUrlsType  = API_LOCAL_ACTUAR;
export const StageEnv: ApiUrlsType  = API_STAGE_ACTUAR;
export const LocalAppUrl: APP_URLType = APP_URL_LOCAL;
export const ProductionAppUrl: APP_URLType = APP_URL_PRODUCTION;
export const DevelopmentAppUrl: APP_URLType = APP_URL_DEVELOPMENT;
export const StageAppUrl: APP_URLType = APP_URL_STAGE;

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');

export const AppConfigProvider = (config: AppConfig): ValueProvider => ({
  provide: APP_CONFIG,
  useValue: config,
});

export const adminTenants = [
  {
    account: 'd2467ad2-8f94-48e8-a938-8355af6b55da',
    name: 'K11',
    blipPassword: '12345'
  },
  {
    account: 'eb7689de-62b1-4e1b-98aa-86ee648bfe91',
    name: 'Actuar',
  },
  {
    account: '77a6bd71-6836-49e7-9f1b-90403fbca069',
    name: 'Treino',
  },
  {
    account: 'f8f293ac-1498-438a-9e60-11d1e8a36c0d',
    name: 'Afig'
  },
  {
    account: '9546e583-6fb6-46bc-aa5f-e49f202d9397',
    name: 'Catracaparaacademia'
  },
  {
    account: '44cc6127-5197-401d-a68d-3bc9a2f4fe3e',
    name: 'Paguela'
  },
  {
    account: '09d6f13e-80c9-4090-b493-6ad3a0ffab1d',
    name: 'ActPaguela'
  },
  {
    account: '95a38624-54bd-4042-8b4e-bfcc3096fb08',
    name: 'ActuarPaguela'
  },
  {
    account: '9c1c84e1-b076-4721-bf60-815d5a208e12',
    name: 'Facilfit'
  },
];
export const devExtremeKey = 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjAwNDVjNWIzLWM3NGQtNDFhNi05MzE5LTRjY2M0YWVkNzU3YyIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.J2fu5uUc29gtzws4eJFDBCmCSxYRw6S0VLWglH+Jcn+PX1chw3uAxGM3sM0duz9ncEx/QqkOXvdfVpm6rOAEJTRXyYlytYGQfBDtU/2kp8UJhfa6RvSCi+VrKGVVb5d09y1ktw==';
