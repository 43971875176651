import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
* @type {RegExp}
* @returns Regular expression to validate URLs
*/
const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
  '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-zA-Z\\d_]*)?$' // fragment locator
);

/**
 * Validator function to check if the control's value is a valid URL.
 * @returns A ValidatorFn that returns a ValidationErrors object or null.
 */
export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isValidUrl = urlPattern.test(value);

    return isValidUrl || !value ? null : { invalidUrl: true };
  };
}
