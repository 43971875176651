import { Injectable } from '@angular/core';
import { User } from '../entities/user';
import { ServerMockService } from './server-mock.service';

@Injectable({
  providedIn: 'root'
})
export class WebAuthnService {

  constructor(private serverMockService: ServerMockService) { }

  webAuthnSignup(user: User): Promise<any> {
    const publicKeyCredentialCreationOptions: PublicKeyCredentialCreationOptions = {
      challenge: this.serverMockService.getChallenge(),
      rp: {
        name: "Actuar",
      },
      user: {
        id: Uint8Array.from(user.id, c => c.charCodeAt(0)),
        name: user.email,
        displayName:  user.email,
      },
      pubKeyCredParams: [
        { alg: -7, type: 'public-key' },
        { alg: -257, type: 'public-key' }
      ],
      authenticatorSelection: {
        userVerification: 'required',
        residentKey: 'preferred',
      },
    };

    return navigator.credentials.create({
      publicKey: publicKeyCredentialCreationOptions,
    });
  }

  webAuthnSignin(user: User[]): Promise<any> {
    const allowCredentials: PublicKeyCredentialDescriptor[] = user[0].credentials.map(c => {
      return { type: 'public-key', id: Uint8Array.from(Object.values(c.credentialId)) };
    });

    const credentialRequestOptions: PublicKeyCredentialRequestOptions = {
      challenge: this.serverMockService.getChallenge(),
      allowCredentials,
    };

    return navigator.credentials.get({
      publicKey: credentialRequestOptions,
    });
  }
}
