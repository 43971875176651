import { inject, Injectable } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Injectable({
  providedIn: 'root'
})
export class CopyClipboard {
  private _clipboardService = inject(ClipboardService);

  public copyValue(value: string): void {
    this._clipboardService.copy(value);
  }
}
