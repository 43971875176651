export interface IAuthAccessToken {
  readonly Token: string;
  readonly Type: string | 'Bearer';
  readonly TenantSupportId: string;
  readonly TokenId: string;
  readonly PersonId: string;
  readonly TenantId: string;
}

export interface IAuthLoginPayload {
  Password: string;
  Email: string;
  Origin?: string | 'actuar';
}

export enum ELocalStorageKeys {
  auth = 'authorization',
  webauthn = 'credentials',
  rememberEmail = 'rememberEmail',
}

export type GetAllPersonsByUserResponse = {
  PersonId: string;
  UserId: string;
  FirstName: string;
  IsActive: boolean;
  "IsOwner": boolean;
  "AppSource": number;
  "TenantId": string;
  "TenantName": string;
}
